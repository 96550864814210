import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import App from './App';
import {FirebaseState} from './context/firebase/FirebaseState';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

import reportWebVitals from './reportWebVitals';
import firebase from './model/firebase';

if (process.env.NODE_ENV === 'production')
    Sentry.init({
        dsn: 'https://fe93cd451e3f4c36816fa2adaba803e7@o4504352451461120.ingest.sentry.io/4504352471121920',
        integrations: [new BrowserTracing(), new Sentry.Replay()],
        replaysSessionSampleRate: 0.1,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0

    });

const test_local = 'http://localhost:4000/';
const test_temp = 'https://testback.unschooler.me/';
// const test_dev = "https://test.back.unschooler.me/";
const test_dev = 'https://back.unschooler.me/';

const httpLink = createHttpLink({
    uri:
        process.env.NODE_ENV === 'production'
            ? 'https://back.unschooler.me/'
            : test_local
});

const authLink = setContext(async (_, {headers}) => {
    const token = firebase.auth().currentUser
        ? await firebase.auth().currentUser.getIdToken()
        : '';
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    };
});

export const clientApollo = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: false,
        typePolicies: {
            Query: {
                fields: {
                    event: {
                        merge: true
                    }
                }
            }
        }
    })
});

// const rootDocument = document.getElementById('root');
const rootDocument = ReactDOM.createRoot(document.getElementById('root'));

rootDocument.render(
    <ApolloProvider client={clientApollo}>
        <React.StrictMode>
            <FirebaseState>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </FirebaseState>
        </React.StrictMode>
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
