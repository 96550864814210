import React, { useState } from 'react';
import { TextToAudioComponent } from '../TextToAudio.component';
import { useTextToAudio, } from '../hook';
import { useGetAudio } from '../../ai'
import { useTrackCredits } from '../../common';
import { openNotification } from '../../notification/Notification.component';

export const TextToAudioContainer = ({ text, savedAudio, taskId, questionId }) => {
    const { getAudioUrl, loading: elevenLoading } = useTextToAudio();
    const { getTextToAudio, loading: aiLoading } = useGetAudio();
    const [error, setError] = useState();
    const [voice, setVoice] = useState();
    const [audioUrl, setAudioUrl] = useState(savedAudio);
    const { trackCredits, isLimitReached } = useTrackCredits();

    const handleGetAudioFromEleven = async () => {
        const { value: voiceId, model } = voice || {};
        let url, error;
        const mermaidRegex = /mermaid\s([\s\S]*?)\smermaid end/g;
        const convertedText = text
            .replace(/https?:\/\/[^\s]+/g, '')//links
            .replace('![]', '')
            .replace(/[*#\n`\/();]+/g, ' ')
            .replace(/<script[^>]*>/g, ' ')
            .replace(/<[^>]*>/g, '')
            .replace(/\s{2,}/g, ' ')
            .replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
            .replace(mermaidRegex, '')
            .replace(/-/g, '')

        let saveData;
        if (taskId) {
            saveData = {
                id: taskId,
                dataBase: 'task',
                prop: 'audioDescription'
            }
        }

        if (questionId) {
            saveData = {
                id: questionId,
                dataBase: 'question',
                prop: 'audioQuestion'
            }
        }

        if (model === 'eleven' || model === 'custom-voice') {
            const resp = await getAudioUrl({
                variables: {
                    text: convertedText,
                    voiceId,
                    ...(saveData && { saveData })
                }
            });

            const { error: elevenError, url: elevenUrl } = resp?.data?.textToAudio || {};
            url = elevenUrl;
            setError(elevenError ? 'Something went wrong, please try again.' : false);
            // }
        } else {
            const isTooLong = convertedText?.length > 4095;
            if (isTooLong) {
                setError('The maximum number of characters for OpenAI should not exceed 4096.');
            } else {
                setError(false);
                url = await getTextToAudio(convertedText, voiceId, saveData);
            }
        }

        if (url) {
            let modelString = model === 'eleven' || model === 'custom-voice' ? 'elevenLabsTurbo' : 'tts'
            await trackCredits(convertedText?.length, modelString);
            await setAudioUrl(url);
            openNotification('topLeft', {
                title: 'Generation audio file',
                description: 'A new audio file has been generated and saved, please reload the page',
                icon: `👩‍🚀`,
                duration: 3,
            });
            // TODO: Alex change on updateTask
            window.location.reload();
        }
    };

    return <TextToAudioComponent
        loading={elevenLoading || aiLoading}
        error={error}
        voice={voice}
        audioUrl={audioUrl}
        isLimitReached={isLimitReached}
        onGetAudio={handleGetAudioFromEleven}
        onChangeVoice={setVoice}
    />
}
